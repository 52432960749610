<template>
    <v-card>
        <v-card-title>
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeForm"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            PROJECTION
            <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col cols="12">
                        <v-select
                            :items="odds"
                            v-model="probability"
                            label="Probability"
                            prepend-icon="mdi-menu"
                        >
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="expectedDate"
                                    label="Expected date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="expectedDate"
                                :min="minDate"
                                @input="menu = false"
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions class="mx-4 pb-5">
            <v-btn color="red" text @click="closeForm">
                CANCEL
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                :loading="loading"
                :disabled="!valid"
                @click="updateProjection"
            >
                SAVE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'ProjectionForm',
    props: {
        projection: Object,
    },
    data: () => ({
        loading: false,
        valid: false,
        probability: null,
        odds: ['High', 'Medium', 'Low'],
        menu: false,
        expectedDate: new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
        )
            .toISOString()
            .substr(0, 10),
        minDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
    }),
    mounted() {
        if (this.projection != {}) {
            this.probability = this.projection.probability
            this.expectedDate = this.projection.expectedDate
        }
    },
    methods: {
        closeForm() {
            this.$emit('closeProjectionForm')
        },
        updateProjection() {
            this.$emit('updateProjection', {
                probability: this.probability,
                expectedDate: this.expectedDate,
            })
        },
    },
}
</script>
